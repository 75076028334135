import axios from "axios";
import UserSvc from "@/service/user";

const getFirstQuestion = (charityId) => {
    return axios.post(
        "/api/conversations",
        charityId
            ? {
                  charity_id: charityId,
              }
            : {}
    );
};

const updateConversation = (body, id) => {
    return axios.post(`/api/conversations/${id}/steps`, body);
};

const undoStep = (step, id) => {
    return axios.delete(`/api/conversations/${id}/steps`, {
        data: {
            step: step,
        },
    });
};

const getZakatDetails = (conversationId) => {
    return axios.post(`/api/conversations/${conversationId}/zakat`, {
        headers: processHeaders(),
    });
};

const fetchConversation = (conversationId) => {
    return new Promise((resolve) => {
        axios
            .get(`/api/conversations/${conversationId}`, {
                headers: processHeaders(),
            })
            .then((resp) => {
                resolve({
                    resp: resp,
                    err: undefined,
                });
            })
            .catch(() => {
                resolve({
                    resp: undefined,
                    err: "unable to fetch conversation",
                });
            });
    });
};

const processHeaders = () => {
    const idToken = UserSvc.getIDToken();
    return idToken
        ? {
              Authorization: idToken,
          }
        : {};
};

export default {
    getFirstQuestion,
    updateConversation,
    undoStep,
    getZakatDetails,
    fetchConversation,
};
